import React, { memo, useCallback, useMemo, useRef } from 'react';
import { type NextBestAction } from '@post-office/confluence-next-best-action';
// import { TestPlacement } from '@post-office/placements--growth/confluence-side-nav/test';
import { Placement as ConfluenceSideNav } from '@post-office/placements/confluence-side-nav';
import { useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import {
	PlacementFetchProvider,
	usePlacementPreloadedData,
} from '@atlassian/confluence-post-office-provider/entry-points/preload-placement';

import { buildCreationLink } from '@confluence/create-blank-fabric-page';
import { useCreateSpaceStore } from '@confluence/create-space/entry-points/createSpace';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

const PO_CONFLUENCE_SIDE_NAV = 'poConfluenceSideNavCreate';

const useIsNav4AndEnabledFF = () => {
	const nav4 = useIsNav4Enabled();
	if (nav4) {
		return fg('post-office-connie-side-nav-v4');
	}

	// If we are not using nav4, return true
	return true;
};

type SideNavClickHandler = (nextBestActionType: NextBestAction, messages: unknown[]) => void;

const nav4ContainerStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '10px',
});

type PostOfficeConfluenceSideNavPlacementProps = {
	nav4?: boolean;
};

export const PostOfficeConfluenceSideNavPlacement = memo(
	({ nav4 = false }: PostOfficeConfluenceSideNavPlacementProps) => {
		const [preloadedInitialData, setInitialData] = usePlacementPreloadedData('confluence-side-nav');
		const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();

		const { push } = useRouteActions();
		const [, { openDrawer }] = useCreateSpaceStore();
		const sideNavRef = useRef(null);

		const onDismiss = useCallback(
			(data: unknown) => {
				setInitialData(data);
			},
			[setInitialData],
		);

		const onSideNavClick = useCallback<SideNavClickHandler>(
			(nextBestActionType: NextBestAction, data: unknown) => {
				setInitialData(data);

				switch (nextBestActionType) {
					case 'CREATE_PAGE': {
						push(
							buildCreationLink({
								contentType: 'page',
								source: PO_CONFLUENCE_SIDE_NAV,
							}),
						);
						break;
					}
					case 'CREATE_WHITEBOARD': {
						push(
							buildCreationLink({
								contentType: 'whiteboard',
								source: PO_CONFLUENCE_SIDE_NAV,
							}),
						);
						break;
					}
					case 'CREATE_SPACE': {
						// The second argument for openDrawer is the location string, which can be undefined or 'topCreateNav'
						// Since we're using the side nav to open the drawer, undefined is passed here.
						openDrawer(sideNavRef, undefined);
						break;
					}
					default:
						return;
				}
			},
			[openDrawer, push, setInitialData],
		);

		const memoizedPlacementProps = useMemo(
			() => ({
				preloadedInitialData,
				onDismiss,
				onSideNavClick,
			}),
			[preloadedInitialData, onDismiss, onSideNavClick],
		);

		const { locale } = useIntl();
		const isEnglishLocale = locale.toLowerCase().startsWith('en');

		const isNav4AndEnabled = useIsNav4AndEnabledFF();

		const PlacementInternals = () => (
			<PlacementFetchProvider>
				{/* @ts-expect-error: Props are not type checked */}
				<ConfluenceSideNav {...memoizedPlacementProps} />
			</PlacementFetchProvider>
		);

		if (!fg('post-office-connie-side-nav-killswitch') || !isNav4AndEnabled) {
			return <></>;
		}

		if (
			isEnglishLocale &&
			expValEquals('post-office_expt_confluence-next-best-actions', 'cohort', 'variation')
		) {
			return nav4 ? (
				<div
					data-testid="confluence-side-nav-placement"
					data-vc="confluence-side-nav-placement"
					{...ssrPlaceholderIdProp}
					css={nav4ContainerStyles}
				>
					<PlacementInternals />
				</div>
			) : (
				<div
					data-testid="confluence-side-nav-placement"
					data-vc="confluence-side-nav-placement"
					{...ssrPlaceholderIdProp}
				>
					<PlacementInternals />
				</div>
			);
		}

		return <></>;
	},
);
